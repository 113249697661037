import React from "react"
import { graphql } from "gatsby"

import { TemplateContextProvider } from 'components/post/TemplateProvider'

import SEO from "components/seo"
import Header from "components/header"
import ImpactArea from "components/sections/impactArea"
import Footer from "components/footer"
import PostIndex from "components/postIndex"

import "styles/pages.scss"
import 'components/postIndex/postIndex.scss'

import graphPostsToCards from 'components/post/postsToCards'
import { postToSchema } from 'components/utils/schemaUtils.js'

const GuidesIndex = props => {
  const { data, pageContext } = props
  const allPosts = graphPostsToCards(data.allWpPost.edges)

  const schemaData = data.allWpPost.edges.map(edge => {
    const post = edge.node
    const article = postToSchema(post)
    return article
  })

  const category = pageContext.category
  const categoryId = pageContext.id

  return (
    <div className={`page featured`}>
        <SEO
          lang="en-US"
          title={data.allWpPage.edges[0].node.seo.title}
          data={data.allWpPage.edges[0].node.seo}
          schemaData={schemaData} />
        <main>
          <Header />
          <ImpactArea data={data.allWpPage.edges[0].node} />
          <PostIndex
            category={category}
            categoryId={categoryId}
            posts={allPosts} />
        </main>
        <Footer />
    </div>
  )
}

export default GuidesIndex

export const pageQuery = graphql`
  query WPQuery {
    allWpPage(filter: { slug: { eq: "features" } }) {
      edges {
        node {
          id
          databaseId
          title
          date
          title
          seo {
            title
            opengraphAuthor
            focuskw
            canonical
            opengraphDescription
            metaRobotsNofollow
            metaDesc
            metaKeywords
            opengraphImage {
              mediaItemUrl
            }
            opengraphModifiedTime
            opengraphPublishedTime
            opengraphPublisher
            opengraphSiteName
            opengraphTitle
            opengraphType
            opengraphUrl
          }
          impactArea {
            textColor
            backgroundColor
            backgroundType
            backgroundImage {
              localFile {
                childImageSharp {
                  gatsbyImageData(
                    layout: CONSTRAINED,
                    placeholder: BLURRED
                  )
                }
              }
            }
            heading
            layout
            vibeset {
              ... on WpVibeset {
                id
                title
                databaseId
                vibesetDetails {
                  gradientImage {
                    mediaItemUrl
                  }
                  gradientVideo {
                    mediaItemUrl
                  }
                }
              }
            }
          }
        }
      }
    }
    allWpPost(
      sort: {order: DESC, fields: date}
      filter: {categories: {nodes: {elemMatch: {slug: {ne: "list"}}}}}
    ) {
      edges {
        node {
          id
          title
          date
          excerpt
          content
          slug
          uri
          author {
            node {
              name
            }
          }
          categories {
            nodes {
              name
            }
          }
          featuredImage {
            node {
              caption
              mediaItemUrl
            }
          }
          postDetails {
            city {
              ... on WpCity {
                title
                databaseId
              }
            }
            vibeset {
              ... on WpVibeset {
                id
                slug
                title
                databaseId
              }
            }
          }
        }
      }
    }
  }
`
